<template>
<h2 class="text-justify">
    O Plano Diretor de Tecnologia da Informação e Comunicação (PDTIC) 
    é um instrumento que permite nortear e acompanhar a atuação da área 
    de Tecnologia da Informação e Comunicação (TIC), definindo estratégias 
    e o plano de ação para implementá-las.
</h2>
</template>

<script>
export default {
    name: "About",
};
</script>
